.Footer-container {
    position: relative;
}
.Footer-container>hr {
    border: 1px solid var(--lightgray);
}
.footer {
    align-items: center;
    flex-direction: column;
    height: 20rem;
    justify-content: center;
    padding: 1rem 2rem;
    display: flex;
    gap: 4rem;
}
 .social-links {
    display: flex;
    gap: 4rem;
}
.social-links>img {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
}
.logo-f>img {
    width: 10rem;
}
.blur-f-1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter:blur(200px);
    background: red;
}
.blur-f-2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter:blur(200px);
    background: rgb(255,115,0);
}